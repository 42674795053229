import Box from "../../components/Atomic/Box"
import Flex from "../../components/Atomic/Flex"
import Grid from "../../components/Atomic/Grid"
import Text from "../../components/Atomic/Text"

import React from "react"
import PageStructure from "../../components/PageStructure"
import { Link } from "gatsby"

export default function projects({ location }) {
  return (
    <PageStructure
      metaContent={`Information regarding non gaming related projects`}
      pageTitle={`Projects`}
      location={location}
    >
      <Box
        as={`section`}
        id={`content`}
        sx={{ maxWidth: ["100%", null, "75%"], paddingY: "sm" }}
      >
        <Text color={`greyScale.1`} py={`sm`}>
          Minor projects that I work on that does not revolve around games.
        </Text>
        <Grid
          as={`ul`}
          sx={{
            // backgroundColor: "greyScale.5",
            backgroundColor: "lightBackground",
            padding: "md",
            borderRadius: "lg",
            marginTop: "sm",
          }}
          columns={[1, 1, 2]}
          gap={2}
        >
          <Box
            as={`li`}
            sx={{
              listStyleType: "none",
              minHeight: "25vh",
              borderRadius: "md",
              // backgroundColor: "greyScale.4",
              backgroundColor: "background",
              padding: "sm",
            }}
          >
            <Flex
              as={Link}
              to={`/runescape`}
              sx={{
                textDecoration: "none",
                color: "greyScale.1",
                justifyContent: "center",
                textShadow: "base",
              }}
            >
              Runescape hiscore
            </Flex>
            <Text sx={{ textAlign: "left" }}>
              The goal of this project is to create a hiscore comparer that can
              compare more than the official sites limit of two.
            </Text>
          </Box>
        </Grid>
      </Box>
    </PageStructure>
  )
}
